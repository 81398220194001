<template>
	<b-container>
		<b-form class="mb-2" inline>
			<div class="d-flex flex-row w-100" v-bind:style="{ fonSize: '15px' }">
				<div class="mt-auto mb-auto w-75">
					<i class="material-icons align-middle m-b-2 m-t-auto">info</i>
					<strong class="mt-auto mb-auto w-50">{{$t('요금표 관리')}}</strong>
					<a href="#" v-show="isGridBoxRightFlag" @click="update()"><i class="material-icons align-middle m-b-auto m-t-auto" :style="{ fontSize: '16px' }">update</i></a>
				</div>
				<b-button v-show="isBtnFlag && isGridBoxRightFlag" ref="pushBtn" class="ml-auto w-25" variant="primary" @click="onPush()">{{$t('추가')}}</b-button>
				<b-button v-show="!isBtnFlag" ref="popBtn" class="ml-auto w-25" variant="danger" @click="onPop()">{{$t('제거')}}</b-button>
			</div>
		</b-form>
		<b-table
			hover
			no-border-collapse
			outlined
			head-variant="light"
			show-empty
			sticky-header="465px"
			ref="costMatchTable"
			:style="'min-width:100%; z-index:0;'"
			:class="'text-center mb-0'"
			:items="items"
			:fields="fields"
			:empty-text="$t('조회된 결과가 없습니다.')"
			:select-mode="'single'"
			selectable
			:details-td-class="'rowDetailStyle'"
			@row-selected="onRowSelected"
			@row-clicked="onRowDetail"
		>
			<template #empty="">
				<h4>{{ "" }}</h4>
			</template>

			<template #cell(selected)="{ rowSelected }">
				<template v-if="rowSelected">
					<span>&check;</span>
					<span class="sr-only">Selected</span>
				</template>
				<template v-else>
					<span>&nbsp;</span>
					<span class="sr-only">Not selected</span>
				</template>
			</template>

			<template #cell(index)="data">
				{{ data.index + 1 }}
			</template>

			<template #cell(delete)="row">
				<a href="#" @click="onRemove(row.item, row.index)"><i class="material-icons align-middle m-b-2">delete</i></a>
			</template>

			<template #row-details="row" :class="'bg-white'">
				<vvo v-slot="v">
					<b-form class="mb-2" inline>
						<div class="d-flex flex-row w-100" v-bind:style="{ fonSize: '15px' }">
							<div class="h5 text-left mt-auto mb-auto w-50">
								<strong v-show="isRegist" class="p-l-1">{{$t('가격정보 등록')}}</strong>
								<strong v-show="!isRegist" class="p-l-1">{{$t('가격정보 설정')}}</strong>
							</div>
							<div class="text-right ml-auto w-10">
								<b-button v-show="isRegist" variant="primary" @click="onValidate(v, onRegist, row.item)">{{$t('저장')}}</b-button>
								<b-button v-show="!isRegist" variant="primary" @click="onValidate(v, onModify, row.item)">{{$t('저장')}}</b-button>
							</div>
						</div>
					</b-form>
					<div class="d-flex align-items-center mt-5">
						<div class="col-3 text-left">
							<span class="h6 mb-0">{{$t('요금표 명칭')}}</span>
						</div>
						<div class="col-9">
							<valid-input
								:vid="'요금표 명칭'"
								:inputType="'text'"
								:inputValue.sync="row.item.costTableName"
								:classList="'text-left'"
								:rules="rules.COST_CONTRACT_NAME"
								:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"
								:isMessage="true"
							></valid-input>

							<!-- <input type="text" class="form-control text-left" ref="costTableName" v-model="row.item.costTableName"/> -->
						</div>
					</div>
					<div class="d-flex align-items-center mt-3">
						<div class="flex-row col-3 text-left">
							<span class="h6 mb-0">{{$t('적용 기간')}}</span>
						</div>
						<div class="col">
							<b-form-datepicker
								:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
								:locale="$store.state.locale"
								size="sm"
								v-model="row.item.applyStartDate"
								class="text-left"
								:placeholder="$t('날짜를 선택하세요.')"
							>
							</b-form-datepicker>
						</div>
						<span>
							~
						</span>
						<div class="col">
							<b-form-datepicker
								:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
								:locale="$store.state.locale"
								size="sm"
								v-model="row.item.applyEndDate"
								class="text-left"
								:placeholder="$t('날짜를 선택하세요.')"
							>
							</b-form-datepicker>
						</div>
					</div>
					<div class="d-flex align-items-center mt-3">
						<div class="col-3 text-left">
							<span class="h6 mb-0">{{$t('기본 요금')}}</span>
						</div>

						<div class="col-9">
							<valid-input
								:vid="'기본요금'"
								:inputType="'number'"
								:inputValue.sync="row.item.basicCost"
								:classList="'text-left'"
								:rules="rules.COST_BASIC_COST"
								:errorMessage="validMessage.FAILEDRULES_REQUIRED_VALID_MESSAGE('기본 요금')"
							></valid-input>
						</div>
					</div>
					<div class="d-flex align-items-center mt-3">
						<div class="flex-row col-3 text-left">
							<span class="h6 mb-0">{{$t('기본요금 산정')}}</span>
						</div>
						<div class="col-9">
							<b-form-select v-model="row.item.basicFeeCalcCode" :options="basicFeeCalcCode"> </b-form-select>
						</div>
					</div>
					<cost-table ref="costTable" :isRegist="isRegist" :costTable="costTable" />
				</vvo>
			</template>
		</b-table>
	</b-container>
</template>

<script>
	import backEndApi from "@api/backEndApi";
	import costTable from "./CostTable.vue";

	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	import AppErrorHandler from "@src/appErrorHandler";

	export default {
		components: {
			costTable,
		},
		props: ["isGridBoxRightFlag"],
		watch: {
			data() {
				this.setFields();
				this.setItems();
			},
			isGridBoxRightFlag() {
				this.resetItmes();
			},
		},
		data() {
			return {
				fields: null,
				items: null,
				emptyText: global.xe.$t("조회자료 없음"),
				selected: [],
				selectedRow: [],
				isRegist: false,
				isBtnFlag: true,
				data: null,
				basicFeeCalcCode: null,
				sesnDvsnInfo: null,
				tagInfo: null,
				costBasicCode: null,
				costDvsnCode: null,
				basicCost: null,
				energySourceCode: null,
				costTable: null,
				rules,
				validMessage,
				popupMessages,
			};
		},
		computed: {},
		async created() {
			this.setFields();
			this.setItems();
			await this.getSesnDvsnCode();
			await this.getBasicFeeCalcCode();
		},
		mounted() {},
		methods: {
			async onRowSelected(item) {
				if (!item.length > 0) {
					this.isRegistTrue();
					this.selectRowFalse();
				} else {
					this.isRegistFlase();
					await this.getCostTableDetail(item[0]);
				}
			},
			onRowDetail(row) {
				// 이전 선택된 row close
				this.selectedRow._showDetails = !this.selectedRow._showDetails;

				// // 선택된 row open
				row._showDetails = !row._showDetails;

				// 선택된 row 저장
				this.selectedRow = row;
			},
			selectRowFalse() {
				this.selectedRow._showDetails = false;
				this.selectedRow = [];
			},
			clearSeclected() {
				this.$refs.costMatchTable.clearSelected();
			},
			onPush() {
				let item = {
					costBasicCode: this.costBasicCode,
					costDvsnCode: this.costDvsnCode,
					basicCost: 0,
					costTableName: "",
					applyEndDate: "9999-12-31",
					applyStartDate: "",
					applyTerm: "",
					_showDetails: true,
				};

				this.items.push(item);
				this.selectRowFalse();

				this.isRegistTrue();
				this.isBtnFlagFlase();

				// this.onRowDetail(item);

				this.costTable = this.initCostTableDetail(item);
			},
			onPop() {
				this.items.pop();
				this.isRegistFlase();
				this.isBtnFlagTrue();
			},
			setData(resultData) {
				this.data = resultData;
			},
			checkApplyDate(start, end) {
				let startDate = new Date(start);
				let endDate = new Date(end);

				if (startDate.getTime() > endDate.getTime()) {
					return false;
				}

				return true;

				// console.log(startDate.getTime(), endDate.getTime())
			},
			setCostInfo(costInfo) {
				console.log(costInfo);
				this.tagInfo = costInfo.tagInfo;
				this.costBasicCode = costInfo.costBasicCode;
				this.costDvsnCode = costInfo.costDvsnCode;
				this.basicCost = costInfo.basicCost;
				this.energySourceCode = costInfo.energySourceCode;
			},
			async onRegist(item) {
				let checkDuplCostParam = {
					costBasicCode: item.costBasicCode,
					costDvsnCode: item.costDvsnCode,
					applyStartDate: item.applyStartDate,
					applyEndDate: item.applyEndDate,
					basicCost: item.basicCost,
				};

				if (this.isEmpty(checkDuplCostParam.applyStartDate) || this.isEmpty(checkDuplCostParam.applyEndDate)) {
					this.alertDanger(popupMessages.COST_TABLE_DATE_REQUIRED_DATE_POPUP_MESSAGE);
					return;
				}

				if (!this.checkApplyDate(item.applyStartDate, item.applyEndDate)) {
					this.alertDanger(popupMessages.COST_TABLE_WRONG_DATE_POPUP_MESSAGE);
					return;
				}

				let param;

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					await backEndApi.cost.checkDuplCostTable(checkDuplCostParam);

					param = {
						info: item,
						detail: this.$refs.costTable.getDetail(),
					};
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COST_TABLE_DATE_OVERLAP_ERROR_POPUP_MESSAGE, this.alertNoti).errHandling();
				}

				try {
					await backEndApi.cost.insertCostTable(param);
					this.alertNoti(popupMessages.COST_TABLE_REGIST_SUCCESS_POPUP_MESSAGE);
					this.isBtnFlagTrue();
					this.$emit("getCostTableList");
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_SAVE_ERROR_POPUP_MESSAGE, this.alertDanger).errHandling();
				}
			},
			async onModify(item) {
				if (this.isEmpty(item.applyStartDate) || this.isEmpty(item.applyEndDate)) {
					this.alertDanger(popupMessages.COST_TABLE_DATE_REQUIRED_DATE_POPUP_MESSAGE);
					return;
				}

				if (!this.checkApplyDate(item.applyStartDate, item.applyEndDate)) {
					this.alertDanger(popupMessages.COST_TABLE_WRONG_DATE_POPUP_MESSAGE);
					return;
				}

				let param = {
					info: item,
					detail: this.$refs.costTable.getDetail(),
				};

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					await backEndApi.cost.insertCostTable(param);
					this.alertNoti(popupMessages.COST_TABLE_UPDATE_SUCCESS_POPUP_MESSAGE);
					this.$emit("getCostTableList");
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_SAVE_ERROR_POPUP_MESSAGE, this.alertDanger)
						.printErrorLog()
						.errHandling();
				}
			},
			async onRemove(item, index) {
				item, index;
				let param = {
					costBasicCode: this.costBasicCode,
					costDvsnCode: this.costDvsnCode,
					basicCost: item.basicCost,
					applyStartDate: item.applyStartDate,
					applyEndDate: item.applyEndDate,
				};
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				const result = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
				if (!result.value) return;

				try {
					await backEndApi.cost.deleteCostTable(param);
					await this.alertNoti(popupMessages.COST_TABLE_DELETE_SUCCESS_POPUP_MESSAGE);
					this.update();
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE, this.alertDanger)
						.printErrorLog()
						.errHandling();
				}
			},
			update() {
				this.isBtnFlagTrue();
				this.$emit("getCostTableList");
			},
			setFields() {
				this.fields = [
					{ key: "costTableName", label: global.xe.$t("요금표 명칭"), thClass: "w-50" },
					{ key: "applyTerm", label: global.xe.$t("적용 기간"), thClass: "w-50" },
					{ key: "delete", label: "", thClass: "w-10" },
				];
			},
			setItems() {
				this.items = this.data;
			},
			async getCostTableDetail(item) {
				let param = {
					costBasicCode: item.costBasicCode,
					costDvsnCode: item.costDvsnCode,
					basicCost: item.basicCost,
					applyStartDate: item.applyStartDate,
					applyEndDate: item.applyEndDate,
				};

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/

				try {
					const result = await backEndApi.cost.getCostTableDetail(param);
					this.costTable = result.data;
				} catch {
					console.log();
				}
			},
			initCostTableDetail(item) {
				console.log(item);
				this.costTable = null;
				let data = {
					sesnDvsnInfo: this.sesnDvsnInfo,
					tagInfo: this.tagInfo,
					costTableInfo: item,
					costDetailInfo: this.initCostDetailInfo(this.sesnDvsnInfo, this.tagInfo),
				};

				return data;
				// this.$refs.costTable.setData(data);
			},
			initCostDetailInfo(sesnDvsnInfo, tagInfo) {
				let result = {};

				//TODO: 코드를 좀더 명시적으로 변경함.
				tagInfo.forEach((tag) => {
					console.log(tag);
					let newCode = tag.code.charAt(0).toLowerCase() + tag.code.slice(1);

					result[newCode] = [];
					sesnDvsnInfo.forEach((code) => {
						result[newCode].push({ sesnDvsnCode: code.code, costValue: 0 });
					});
				});

				return result;
			},
			async getSesnDvsnCode() {
				this.sesnDvsnInfo = [];

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					const result = await backEndApi.publicCode.getSmallCodeList("sesnDvsnCode");
					result.data.forEach((data) => {
						this.sesnDvsnInfo.push({ code: data.smallCode, codeName: data.smallCodeName });
					});
				} catch (err) {
					new AppErrorHandler(err).printErrorLog().errHandling();
				}
			},
			async getBasicFeeCalcCode() {
				this.basicFeeCalcCode = [];
				const result = await backEndApi.publicCode.getSmallCodeList("basicFeeCalcCode");
				// console.log('getSmallCodeList', data);
        console.log(result);
				result.data.forEach((data) => {
					this.basicFeeCalcCode.push({ value: data.smallCode, text: data.smallCodeName });
				});
			},
			resetItmes() {
				this.items = [];
			},
			isBtnFlagTrue() {
				this.isBtnFlag = true;
			},
			isBtnFlagFlase() {
				this.isBtnFlag = false;
			},
			isRegistTrue() {
				this.isRegist = true;
			},
			isRegistFlase() {
				this.isRegist = false;
			},
		},
	};
</script>

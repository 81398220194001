<template>
	<div>
			<div class="row p-10">
				<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
					<div class="pt-2 p-l-15">
						<span>{{$t('계측기 유형')}}</span>
					</div>
					<div class="p-l-5">
						<select class="form-control" :ref="'meterTypeCode'" @change="onChangeCombo">
							<option value="">{{$t('전체')}}</option>
    
							<option v-for="(data, index) in meterTypeCodeList" :key="index" :value="data.meterTypeCode">
								{{ data.meterTypeCodeName }}
							</option>
						</select>
					</div>
				</div>
				<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
					<div class="pt-2 p-l-15">
						<span>{{$t('계측기 운영 여부')}}</span>
					</div>
					<div class="p-l-5">
						<select class="form-control" :ref="'enable'" @change="onChangeCombo">
							<option value="">{{$t('전체')}}</option>
							<option value="0">{{$t('운영안함')}}</option>
							<option value="1">{{$t('운영')}}</option>
						</select>
					</div>
				</div>
				<div class="font-weight-bold d-flex flex-row p-l-5" :style="{ fontSize: '15px'}">
					<valid-input ref="keyword" :inputType="'text'" :inputValue.sync="searchKeyWord" :rules="rules.CLBRN_SEARCH_RULE" :NonStatus="true" @keydownMethod="onSearch"></valid-input>

					<!-- <input :ref="'keyword'" class="form-control" @keydown.enter="onSearch"/> -->
				</div>
				<div class="font-weight-bold d-flex flex-row p-l-5" :style="{ fontSize: '15px' }">
					<button class="form-control" @click="onSearch">{{$t('조회')}}</button>
				</div>
			</div>
	</div>
</template>

<script>
	import backEndApi from "../../../api/backEndApi";
	import * as rules from "@src/consts/ruleConsts.js";

	export default {
		name: "ClbrnHistSearch",
		props: [],
		components: {},
		watch: {},
		data() {
			return {
				channelClbrnList: null,
				meterTypeCodeList: [],
				searchKeyWord: "",
				rules
			};
		},
		created() {
			// 처음 진입 시에만 호출
			this.init();
		},
		mounted() {},
		methods: {
			init() {
				this.getSmallCodeList();
				this.getChannelClbrnList();
			},
			getSmallCodeList() {
				// tb_meter_type 테이블 미사용 처리. 2024-01-22 by badblock

				// backEndApi.meterType.getMeterTypeList().then(({ data }) => {
				// 	for(let i = 0; i < data.length; i++) {
				// 		if(data[i].enabled == "Y") {
				// 			this.meterTypeCodeList.push(data[i]);
				// 		}
				// 	}
				// 	console.log(this.meterTypeCodeList)
				// });
				
				this.meterTypeCodeList = null;
				backEndApi.publicCode.getSmallCodeList("meterTypeCode", "", "Y").then(({ data }) => {
					this.meterTypeCodeList = data;
					this.$emit("search:data", data);
				});
			},
			getChannelClbrnList() {
				this.channelClbrnList = null;
				backEndApi.clbrtnHist.getFacilityClbrnList().then(({ data }) => {
					this.channelClbrnList = data;
					this.$emit("search:data", data);
				});
			},
			// Event
			onChangeCombo() {
				this.onSearch();
			},

			onSearch() {

        console.log(this.$refs);
				var meterTypeCode = this.$refs.meterTypeCode.value; // 계측기 유형
				var enable = this.$refs.enable.value; // 계측기 운영여부
				var keyword = this.$refs.keyword.value; // 키워드

				console.log("################");
				console.log(meterTypeCode);
				console.log(enable);
				console.log(keyword);
				console.log("################");

        console.log(this.cannelClbrnList);

				var result = this.channelClbrnList
					.filter((data) => (meterTypeCode == "" ? data.meterTypeCode == data.meterTypeCode : data.meterTypeCode == meterTypeCode))
					.filter((data) => (enable == "" ? data.enable == data.enable : data.enable == enable))
					.filter((data) => (keyword == "" ? data.facilityName == data.facilityName : data.facilityName.includes(keyword)));

				console.log("################");
				console.log(result);
				console.log("################");

				this.$emit("search:data", result);
			},
			onNew() {
				console.log("point management new");
				this.$emit("new");
			},
		},
	};
</script>

<style scoped></style>
